
import { defineStore } from 'pinia'
import { ContentCardItem } from "~/types";

export const useChannelsStore = defineStore('channelsStore', {
  state: () => ({
    channels: [
      {
        id: 0,
        mediaSrc: "zap-icone.png",
        title: "WhatsApp",
        text: "Receba avisos diretamente no seu app favorito de mensagens."
      },
      {
        id: 1,
        mediaSrc: "carta-icone.png",
        title: "E-mail",
        text: "Não perca nenhuma atualização, nós te avisamos diretamente no seu e-mail."
      },
      {
        id: 2,
        mediaSrc: "sms-icone.png",
        title: "SMS e Push",
        text: "Comunicação rápida, curta e direta. Tudo na palma da sua mão."
      },
      {
        id: 3,
        mediaSrc: "telefone-icone.png",
        title: "Telefone",
        text: "Receba ligações dos nossos atendentes virtuais. A tecnologia a seu serviço."
      },
    ] as Array<ContentCardItem>
  }),

  getters: {
    getOurChannels: (state) => state.channels,
  },
})